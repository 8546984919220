import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {apiBearer} from 'Helpers/api'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import Slider from 'react-slick'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Container,
} from 'reactstrap'

export default function DetailProduct() {
  const {id} = useParams()
  console.log(id)

  const {data} = useQuery(
    ['product-detail', id],
    () =>
      new Promise((resolve, reject) => {
        try {
          apiBearer.get(`/pub/product/${id}/detail`).then((response) => {
            resolve(response?.data?.data)
          })
        } catch (err) {
          reject(err)
        }
      }),
  )

  const [open, setOpen] = useState('1')
  const toggle = (id) => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Products' />
        <div className='card'>
          <Slider autoplay={false} dots className='slider-wrapper card-img-top'>
            {data?.images?.map((image, imageIdx) => (
              <img
                key={imageIdx}
                // className='img-detail'
                src={image?.photo}
                alt={data?.name + imageIdx}
              />
            ))}
          </Slider>
          <div className='card-body'>
            <h4 className='card-title mb-2 text-center'>{data?.name}</h4>
            <Accordion open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId='1'>Deskripsi</AccordionHeader>
                <AccordionBody accordionId='1'>
                  <div dangerouslySetInnerHTML={{__html: data?.description}} />
                </AccordionBody>
              </AccordionItem>
            </Accordion>
            {/* <div class='accordion' id='accordionExample'>
              <div class='accordion-item'>
                <h2 class='accordion-header' id='headingOne'>
                  <button
                    class='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseOne'
                    aria-expanded='true'
                    aria-controls='collapseOne'
                  >
                    Accordion Item #1
                  </button>
                </h2>
                <div
                  id='collapseOne'
                  class='accordion-collapse collapse show'
                  aria-labelledby='headingOne'
                  data-bs-parent='#accordionExample'
                >
                  <div class='accordion-body'>
                    <strong>This is the first item's accordion body.</strong> It
                    is shown by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class='accordion-item'>
                <h2 class='accordion-header' id='headingTwo'>
                  <button
                    class='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseTwo'
                    aria-expanded='false'
                    aria-controls='collapseTwo'
                  >
                    Accordion Item #2
                  </button>
                </h2>
                <div
                  id='collapseTwo'
                  class='accordion-collapse collapse'
                  aria-labelledby='headingTwo'
                  data-bs-parent='#accordionExample'
                >
                  <div class='accordion-body'>
                    <strong>This is the second item's accordion body.</strong>{' '}
                    It is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class='accordion-item'>
                <h2 class='accordion-header' id='headingThree'>
                  <button
                    class='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseThree'
                    aria-expanded='false'
                    aria-controls='collapseThree'
                  >
                    Accordion Item #3
                  </button>
                </h2>
                <div
                  id='collapseThree'
                  class='accordion-collapse collapse'
                  aria-labelledby='headingThree'
                  data-bs-parent='#accordionExample'
                >
                  <div class='accordion-body'>
                    <strong>This is the third item's accordion body.</strong> It
                    is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  )
}
