import React, {useCallback} from 'react'
import ReactPaginate from 'react-paginate'

/**
 *
 * @param {{page:number, total:number, perPage:number, onPageChange:Function}} param0
 * @returns
 */
const NewPagination = ({
  page,
  total,
  perPage,
  onPageChange,
  onPerPageChange,
}) => {
  const handlePageChange = useCallback((e) => {
    onPageChange(e)
  }, [])

  return (
    <div>
      <ReactPaginate
        nextClassName='btn btn-outline-dark'
        nextLabel='Next &nbsp; →'
        previousClassName='btn btn-outline-dark'
        previousLabel='← &nbsp; Prev'
        onPageChange={handlePageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={Math.ceil(total / perPage)}
        containerClassName='pagination justify-content-center'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        breakLabel='...'
        renderOnZeroPageCount={null}
        activeClassName='active'
        // activeLinkClassName='text-white'
        forcePage={Math.ceil(page / perPage)}
      />
    </div>
  )
}

export default NewPagination
