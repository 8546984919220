import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import flatpickr from 'flatpickr'
import moment from 'moment'
import {useRoutes} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Routes from './Routes'
import './assets/scss/themes.scss'
const Id = require('flatpickr/dist/l10n/id.js').default.id
flatpickr.localize(Id) // default locale is now Id
var idLocale = require('moment/locale/id')

moment.locale('id', idLocale)

function App() {
  const routes = useRoutes(Routes)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retryOnMount: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      {routes}
      <ToastContainer />
    </QueryClientProvider>
  )
}

export default App
