import {yupResolver} from '@hookform/resolvers/yup'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {BASE_API_URL} from 'configs'
import flatpickr from 'flatpickr'
import {apiBearer} from 'Helpers/api'
import {validName, validNumbers} from 'Helpers/regex_validation'
import {showSuccess} from 'Helpers/show_toast'
import {useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {Col, Input, Label, Row} from 'reactstrap'
import {logoutAction} from 'Redux/actions/auth'
import * as Yup from 'yup'

export default function PersonalDetail({
  is_approved,
  dataProfile,
  isFetching,
  status,
  error,
  refetch,
}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const dobRef = useRef()

  const validation = Yup.object().shape({
    name: Yup.string()
      .required('Full Name harus diisi')
      .min(3, 'Full Name minimal terdiri dari 3 karakter')
      .test('test-valid-name', 'Full Name hanya boleh berisi huruf', (v) =>
        validName.test(v),
      ),
    email: Yup.string().required('Email harus diisi').strict(true),
    bank_account: Yup.string().required(),
    bank_name: Yup.string().required(),
    phone: Yup.string()
      .required('Phone harus diisi')
      .matches(validNumbers, {message: 'Phone hanya boleh berisi angka'}),
    gender: Yup.string().required('Gender harus diisi').strict(true),
    dob: Yup.mixed(),
    address: Yup.string().required('Address harus diisi'),
    province: Yup.string().required('Province harus diisi'),
    city: Yup.string().required('City harus diisi'),
    district: Yup.string().required('District harus diisi'),
    village: Yup.string().required('Village harus diisi'),
    zip_code: Yup.string()
      .required('Zip code harus diisi')
      .matches(validNumbers, {message: 'Zip code hanya boleh berisi angka'}),
    akta_pendirian: Yup.mixed(),
    npwp: Yup.mixed(),
    ktp: Yup.mixed(),
    logo: Yup.mixed(),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      code: '',
      name: '',
      email: '',
      picName: '',
      phone: '',
      address: '',
      bank_account: '',
      bank_name: '',
      province: '',
      city: '',
      district: '',
      village: '',
      zip_code: '',
      dob: '',
      akta_pendirian: '',
      gender: '',
      logo: '',
    },
  }
  const {handleSubmit, formState, control, setValue} = useForm(formOptions)
  const {errors} = formState

  useEffect(() => {
    if (dobRef.current) {
      flatpickr(dobRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue('dob', sDate)
        },
      })
    }
  }, [])

  const onSubmit = async (values) => {
    const formData = new FormData()
    const data = JSON.stringify({
      code: values.code,
      name: values.name,
      pic_email: values.email,
      bank_account: values.bank_account,
      bank_name: values.bank_name,
      pic_phone: values.phone,
      gender: values.gender,
      date_of_birth: values.dob,
      // date_of_birth: values.dob,
      address: values.address,
      province_id: values.province,
      city_id: values.city,
      district_id: values.district,
      village_id: values.village,
      zip_code: values.zip_code,
    })
    formData.append('data', data)
    formData.append('aktaPendirian', values.akta_pendirian)
    formData.append('npwp', values.npwp)
    formData.append('ktp', values.ktp)
    formData.append('logo', values.logo)
    setLoading(true)
    try {
      await apiBearer.patch(BASE_API_URL + '/profile/update', formData)
      showSuccess('Profile is successfully updated')
      refetch()
    } catch (error) {
      // showError(
      //   error.response?.data?.message ??
      //     "Something wrong, please check the input"
      // );
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col lg={3} md={6} sm={12}>
          <div className={`mb-3 ${errors.name ? 'error-form' : ''}`}>
            <label htmlFor='name' className='form-label'>
              Company Name
            </label>
            <div className='position-relative auth-pass-inputgroup '>
              <Controller
                id='name'
                name='name'
                type='text'
                control={control}
                render={({field}) => <Input {...field} value={field.value} />}
              />
            </div>
            <div className='error-form-info badge badge-soft-danger'>
              <img
                alt=''
                src='/assets/icons/triangle-alert-fill.svg'
                className='me-1'
              />{' '}
              {errors.name?.message}
            </div>
          </div>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <div className={`mb-3 ${errors.email ? 'error-form' : ''}`}>
            <label htmlFor='email' className='form-label'>
              Email
            </label>
            <div className='position-relative auth-pass-inputgroup'>
              <Controller
                id='email'
                name='email'
                control={control}
                render={({field}) => <Input {...field} value={field.value} />}
              />
              <div
                className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                type='button'
              >
                <i className='ri-mail-line align-middle '></i>
              </div>
            </div>
            <div className='error-form-info badge badge-soft-danger'>
              <img
                alt=''
                src='/assets/icons/triangle-alert-fill.svg'
                className='me-1'
              />{' '}
              {errors.email?.message}
            </div>
          </div>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.bank_account}
            message={errors.bank_account?.message}
          >
            <Label htmlFor='bank_account' className='form-label'>
              Bank Account
            </Label>
            <Controller
              id='bank_account'
              name='bank_account'
              type='bank_account'
              control={control}
              render={({field}) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.phone}
            message={errors.phone?.message}
          >
            <Label htmlFor='phone' className='form-label'>
              Phone
            </Label>
            <Controller
              id='phone'
              name='phone'
              type='phone'
              control={control}
              render={({field}) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper error={errors.dob} message={errors.dob?.message}>
            <Label htmlFor='dob' className='form-label'>
              DOB
            </Label>
            <Controller
              id='dob'
              name='dob'
              control={control}
              render={({field}) => (
                <input ref={dobRef} className='form-control' type='text' />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.address}
            message={errors.address?.message}
          >
            <Label htmlFor='address' className='form-label'>
              Address
            </Label>
            <Controller
              id='address'
              name='address'
              type='address'
              control={control}
              render={({field}) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>
        </Col>
      </Row>
      <div className='mt-4 align-items-center d-flex justify-content-end gap-3'>
        <button
          color='success'
          className='btn btn-success'
          type='submit'
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Update'}
        </button>
        {!is_approved ? (
          <button
            className='btn btn-outline-dark'
            onClick={() => dispatch(logoutAction())}
          >
            Logout
          </button>
        ) : null}
      </div>
    </form>
  )
}
