import {useQuery} from '@tanstack/react-query'
import NewPagination from 'Components/Common/Table/NewPagination'
import {apiBearer} from 'Helpers/api'
import useDebounce from 'Hooks/useDebounce'
import {useCallback, useState} from 'react'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {Card, CardBody, Col, Container, Row} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import {DATA_LIMIT} from '../../configs'

export default function Products() {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [category_id, set_category_id] = useState(null)
  const [childLabel, setChildLabel] = useState(null)

  const {data: categoriesData} = useQuery(
    ['category'],
    () =>
      new Promise((resolve, reject) => {
        try {
          apiBearer.get('/pub/category/all').then((response) => {
            resolve(response?.data?.data)
          })
        } catch (error) {
          reject(error)
        }
      }),
  )
  const categoryOptions = categoriesData?.map(({id, name, children}) =>
    children && children.length > 0
      ? {
          label: name,
          options: children.map((child) => ({
            label: child?.name,
            value: child?.id,
          })),
        }
      : {
          label: name,
          value: id,
        },
  )

  const debouceSearch = useDebounce(search, 700)
  const {data: productData} = useQuery(
    ['product-list', page, category_id, debouceSearch],
    () =>
      new Promise((resolve, reject) => {
        const params = {page, search: debouceSearch, category_id}
        try {
          apiBearer.get('/pub/product/lists', {params}).then((response) => {
            resolve(response?.data?.data)
          })
        } catch (err) {
          reject(err)
        }
      }),
  )

  const onPageChange = useCallback((i) => {
    setPage(i.selected)
  }, [])

  const onPerPageChange = useCallback((i) => {
    setLimit(i)
    setPage(0)
  }, [])

  const handleSelect = useCallback((newValue) => {
    if (!newValue) {
      setChildLabel('')
      set_category_id('')
    } else {
      setChildLabel(newValue.label)
      set_category_id(newValue.value)
    }
  }, [])

  const handleSearch = (e) => setSearch(e.target.value)

  const selected = categoryOptions?.find(
    ({value, options}) =>
      value === category_id ||
      options?.find(({value}) => value === category_id),
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Products' pageTitle='Products' />
        <Card className='mb-5'>
          <CardBody>
            <Row>
              <Col md={6}>
                <Select
                  className='my-2'
                  options={categoryOptions}
                  value={
                    selected
                      ? {
                          ...selected,
                          label: selected?.label + ' - ' + childLabel,
                        }
                      : {}
                  }
                  onChange={handleSelect}
                  isClearable
                  placeholder='Plih Kategori'
                />
              </Col>
              <Col md={6}>
                <div className='flex-shrink-0 my-2'>
                  <div className='search-box'>
                    <input
                      placeholder='Cari...'
                      type='text'
                      className='form-control form-control'
                      onChange={handleSearch}
                    />
                    <i className='ri-search-line search-icon'></i>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row>
          {productData?.data?.map((data) => (
            <Col lg={4} md={6} xs={12} key={data?.id}>
              <div className='card'>
                <img
                  className='card-img-top img-fluid my-3'
                  style={{height: 140, objectFit: 'contain'}}
                  src={data?.images && data?.images[0]?.photo}
                  alt={data?.name}
                />
                <div className='card-body'>
                  <h4 className='card-title mb-2 text-center'>{data?.name}</h4>
                  <div className='text-center'>
                    <Link
                      to={`/app/products/${data?.id}`}
                      className='link link-primary'
                    >
                      Detail
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <NewPagination
            total={productData?.total || 0}
            page={productData?.offset ?? 0}
            perPage={productData?.limit ?? 10}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
          />
        </Row>
      </Container>
    </div>
  )
}
