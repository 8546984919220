import ForgotPassword from 'Pages/Auth/ForgotPassword'
import ResetPassword from 'Pages/Auth/ResetPassword'
import VerifEmail from 'Pages/Auth/VerifEmail'
import PaymentRedirect from 'Pages/PaymentRedirect'
import DetailProduct from 'Pages/Products/Detail'
import Products from 'Pages/Products/List'
import DetailSymposium from 'Pages/Symposium/Detail'
import SymposiumSchedule from 'Pages/Symposium/List'
import {Navigate} from 'react-router-dom'
import AuthenticationLayout from '../Layout/AuthenticationLayout'
import Layout from '../Layout/index'
import SignIn from '../Pages/Auth/SignIn'
import Dashboard from '../Pages/Dashboard'
import EditCreateUser from '../Pages/Participant/Edit'
import User from '../Pages/Participant/List'
import UserProfile from '../Pages/Profile/Profile'

const publicRoutes = [
  {
    path: '/',
    element: <AuthenticationLayout />,
    children: [
      {path: 'login', element: <SignIn />},
      {path: 'verif-email/:token/:type', element: <VerifEmail />},
      {path: 'forgot-password', element: <ForgotPassword />},
      {path: 'password-reset/:token/:type', element: <ResetPassword />},
      {path: 'payment/:status', element: <PaymentRedirect />},
      {path: '/', element: <Navigate to='/app' />},
    ],
  },
]

const authProtectedRoutes = [
  {
    path: 'app',
    element: <Layout />,
    children: [
      {path: '', element: <Dashboard />},
      {path: 'dashboard', element: <Dashboard />},
      {path: 'profile', element: <UserProfile />},
      {path: 'participants', element: <User />},
      {path: 'participants/edit/:id', element: <EditCreateUser />},
      {path: 'participants/create', element: <EditCreateUser />},
      {path: 'products', element: <Products />},
      {path: 'products/:id', element: <DetailProduct />},
      {path: 'symposium', element: <SymposiumSchedule />},
      {path: 'symposium/:id', element: <DetailSymposium />},
    ],
  },
]

export {authProtectedRoutes, publicRoutes}
