import ReactPaginate from "react-paginate";

/**
 *
 * @param {{page:number, total:number, perPage:number, onPageChange:Function}} param0
 * @returns
 */
export default function MyPagination({ page, total, perPage, onPageChange, onPerPageChange }) {
  // const from = page * perPage - perPage + 1;
  const from = page + 1;
  const to = page + perPage;
  return (
    <div className="mt-4 d-flex justify-content-between">
      <div>
        {from || 0} - {Math.min(to || 0, total)} dari {total}
      </div>
      <div>
        <ReactPaginate
          nextClassName="btn btn-outline-dark"
          nextLabel="Selanjutnya"
          previousClassName="btn btn-outline-dark"
          previousLabel="Sebelumnya"
          onPageChange={(e) => {
            onPageChange(e);
          }}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={Math.ceil(total / perPage)}
          containerClassName="d-flex align-items-center list-unstyled gap-1 mb-0"
          pageClassName="btn btn-sm"
          pageLinkClassName="btn btn-sm"
          breakLabel="..."
          renderOnZeroPageCount={null}
          activeClassName="btn-primary text-white"
          activeLinkClassName="text-white"
          forcePage={Math.floor(page / perPage)}
        />
      </div>
    </div>
  );
}
