import {useQuery} from '@tanstack/react-query'
import {apiBearer} from 'Helpers/api'
import {Link} from 'react-router-dom'
import {Col, Container, Row} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'

export default function SymposiumSchedule() {
  const {data: symposiumData} = useQuery(
    ['symposium-list'],
    () =>
      new Promise((resolve, reject) => {
        try {
          apiBearer.get('/pub/symposium-schedule/all').then((response) => {
            resolve(response?.data)
          })
        } catch (err) {
          reject(err)
        }
      }),
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Products' pageTitle='Products' />
        <Row>
          {symposiumData?.data?.map((data) => (
            <Col lg={4} md={6} xs={12} key={data?.id}>
              <div className='card'>
                <img
                  className='card-img-top img-fluid my-3'
                  style={{height: 140, objectFit: 'contain'}}
                  src={data?.slider_photo}
                  alt={data?.name}
                />
                <div className='card-body'>
                  <h4 className='card-title mb-2 text-center'>{data?.name}</h4>
                  <div className='text-center'>
                    <Link
                      to={`/app/symposium/${data?.id}`}
                      className='link link-primary'
                    >
                      Detail
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
