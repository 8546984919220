import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {apiBearer} from 'Helpers/api'
import icCalendar from 'assets/images/ic-calendar.svg'
import icClock from 'assets/images/ic-clock.svg'
import icPin from 'assets/images/ic-pin.svg'
import moment from 'moment'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {Container} from 'reactstrap'

export default function DetailSymposium() {
  const {id} = useParams()
  console.log(id)

  const {data} = useQuery(
    ['symposium-detail', id],
    () =>
      new Promise((resolve, reject) => {
        try {
          apiBearer
            .get(`/pub/symposium-schedule/${id}/detail`)
            .then((response) => {
              resolve(response?.data?.data)
            })
        } catch (err) {
          reject(err)
        }
      }),
  )

  const [open, setOpen] = useState('1')
  const toggle = (id) => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Symposium' />
        <div className='card'>
          {/* <img
            className='card-img-top img-fluid my-3'
            style={{objectFit: 'contain', maxHeight: 400}}
            src={data?.photo}
            alt={data?.name}
          /> */}
          <div className='card-body'>
            <img src={data?.photo} alt='' className='img-fluid' />
            <div class='caption-symposium'>
              <h2 className='my-3'>{data?.title}</h2>

              <p class='d-flex align-items-center mb-3'>
                <img src={icPin} alt='' />{' '}
                <span className='ms-3'>{data?.location}</span>
              </p>
              <div class='d-flex'>
                <p class='d-flex align-items-center'>
                  <img src={icCalendar} alt='' />{' '}
                  <span className='ms-3'>
                    {moment(data?.event_date).format('dddd, MMM Do YYYY')}
                  </span>
                </p>
                <p class='d-flex align-items-center ms-3'>
                  <img src={icClock} alt='' /> {data?.event_time_start} -{' '}
                  {data?.event_time_end}
                </p>
              </div>

              <div
                class='desc-symposium'
                dangerouslySetInnerHTML={{__html: data?.description}}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
