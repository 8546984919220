import {yupResolver} from '@hookform/resolvers/yup'
import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {apiBasic} from 'Helpers/api'
import {validPhone} from 'Helpers/regex_validation'
import {ParticipantRQ} from 'Hooks/Queries'
import useDebounce from 'Hooks/useDebounce'
import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {ReactSearchAutocomplete} from 'react-search-autocomplete'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'

const specializationOptions = [
  {label: 'DSA', value: 'DSA'},
  {label: 'PPDS', value: 'PPDS'},
  {label: 'GP', value: 'GP'},
  {label: 'LAINNYA', value: 'LAINNYA'},
]

export default function EditCreateParticipant(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const profile = useSelector((state) => state.auth.profile)
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [search, setSearch] = useState('')
  const [items, setItems] = useState([])
  const debounsceSearch = useDebounce(search, 500)

  useQuery(['candidates-search', debounsceSearch], () =>
    apiBasic
      .get(`pub/candidate/lists?page=0&search=${debounsceSearch}&size=10`)
      .then((response) => {
        setItems(response?.data?.data?.data ?? [])
        return response?.data?.data
      })
      .catch((error) => {
        setItems([])
        return error
      }),
  )

  const formatResult = (item) => {
    return (
      <div className='d-flex'>
        <span style={{display: 'block', minWidth: 100}}>{item.name}</span>
      </div>
    )
  }

  const validation = Yup.object().shape({
    name: Yup.string().required('Nama tidak boleh kosong'),
    phone: Yup.string()
      .required('Nomor hp tidak boleh kosong')
      .test('test-valid-name', 'Nomor hp tidak valid', (v) =>
        validPhone.test(v),
      ),
    specialization: Yup.string().required('Spesialisasi tidak boleh kosong'),
    sales_promotion_id: Yup.string().required('Permission tidak boleh kosong'),
    province: Yup.string().required('Provinsi tidak boleh kosong'),
    city_id: Yup.string().required('Kota tidak boleh kosong'),
    office_address: Yup.string().required('Alamat Kantor tidak boleh kosong'),
    other_specialization: Yup.string().test(
      'is-required',
      'Wajib diisi',
      function (value, ctx) {
        const {specialization} = ctx.parent
        if (specialization === 'LAINNYA') return !!value
        return true
      },
    ),
  })

  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      phone: '',
      specialization: '',
      sales_promotion_id: profile?.id,
      city_id: '',
      province: '',
      office_address: '',
    },
  }
  const {handleSubmit, formState, control, reset, register, setValue, watch} =
    useForm(formOptions)
  const {errors} = formState

  const {mutate: updateParticipant, isLoading: updateLoading} =
    ParticipantRQ.useUpdateData(navigate)()

  const _updateParticipant = (values) => {
    const data = values
    data.id = id
    const {sales_promotion_id: _, ...dataWithout} = values
    updateParticipant(dataWithout)
  }

  const {mutate: addParticipant, isLoading: createLoading} =
    ParticipantRQ.useAddData(navigate)()

  const _createParticipant = (values) => {
    addParticipant(values)
  }

  const {data, error, isFetching, status} = ParticipantRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        name: data?.data?.name ?? '',
        phone: data?.data?.phone ?? '',
        specialization: data?.data?.specialization ?? '',
        sales_promotion_id: data?.data?.sales_promotion?.id ?? '-',
        city_id: data?.data?.city?.id ?? '',
        province: data?.data?.province ?? '',
        office_address: data?.data?.office_address ?? '',
      })
      if (data?.data?.specialization === 'LAINNYA')
        setValue('other_specialization', data?.data?.other_specialization ?? '')

      if (data?.data?.province)
        setSelectedProvince({
          label: data?.data?.province?.name,
          value: data?.data?.province?.id,
        })
    }
    if (!isFetching && status === 'error') {
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching, data?.data?.province])

  const isLoading = createLoading || updateLoading
  const submitBtnText = () => {
    if (isLoading) return 'Loading...'
    return id ? 'Update' : 'Create'
  }

  const watchspecialization = watch('specialization')

  const isOtherSpecialization = watchspecialization === 'LAINNYA'

  const {data: dataProvinces} = useQuery(
    ['province'],
    () =>
      new Promise((resolve) => {
        apiBasic
          .get('/pub/location/provinces')
          .then((response) => {
            resolve(
              response?.data?.data?.map((v) => ({
                label: v?.name,
                value: v?.id,
              })),
            )
          })
          .catch(() => resolve([]))
      }),
  )

  const {data: dataCities} = useQuery(
    ['cities', selectedProvince],
    () =>
      new Promise((resolve) => {
        apiBasic
          .get(`/pub/location/${selectedProvince?.value}/regencies`)
          .then((response) => {
            const processedData = response?.data?.data?.map((v) => ({
              label: v?.name,
              value: v?.id,
            }))

            if (data?.data?.city && !isFetching) {
              setValue('city_id', data?.data?.city?.id)
            }
            resolve(processedData)
          })
          .catch(() => resolve([]))
      }),
    {
      enabled: !!selectedProvince,
    },
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Participant' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateParticipant : _createParticipant,
              )}
            >
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Participant
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label
                          htmlFor='name'
                          className='form-label text-uppercase'
                        >
                          Name <span className='mandatory'>*</span>
                        </Label>
                        <Controller
                          name='name'
                          control={control}
                          render={({field}) => (
                            <>
                              <ReactSearchAutocomplete
                                // className='form-control relative p-0'
                                showIcon={false}
                                styling={{
                                  boxShadow: 'none',
                                  border: 'none',
                                  backgroundColor: 'white',
                                  clearIconMargin: '',
                                  zIndex: 5,
                                  height: 37,
                                }}
                                showClear={false}
                                showNoResults={false}
                                items={items}
                                formatResult={formatResult}
                                showItemsOnFocus
                                onSelect={({name}) => {
                                  field.onChange(name)
                                }}
                                onSearch={(input) => {
                                  field.onChange(input)
                                  setSearch(input)
                                }}
                                className='form-control relative pt-2 ps-0 rounded-end'
                                inputSearchString={id ? data?.data?.name : ''}
                                {...register('name')}
                              />
                            </>
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.phone}
                        message={errors.phone?.message}
                      >
                        <Label htmlFor='phone'>
                          Nomor Hp <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('phone')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.specialization}
                        message={errors.specialization?.message}
                      >
                        <Label
                          htmlFor='specialization'
                          className='form-label text-uppercase'
                        >
                          Spesialisasi <span className='mandatory'>*</span>
                        </Label>
                        <Controller
                          control={control}
                          name='specialization'
                          render={({field: {onChange, value}}) => (
                            <Select
                              value={
                                specializationOptions.find(
                                  (op) => op.value === value,
                                ) ?? {}
                              }
                              options={specializationOptions}
                              onChange={(newValue) => {
                                if (newValue.value) {
                                  onChange(newValue.value)
                                }
                              }}
                            />
                          )}
                        />
                      </ErrorFieldWrapper>

                      {isOtherSpecialization ? (
                        <ErrorFieldWrapper
                          className='mt-2'
                          error={errors.other_specialization}
                          message={errors.other_specialization?.message}
                        >
                          <input
                            className='form-control'
                            {...register('other_specialization')}
                            placeholder='Jelaskan spesialisasi anda'
                          />
                        </ErrorFieldWrapper>
                      ) : null}
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.office_address}
                        message={errors.office_address?.message}
                      >
                        <Label
                          htmlFor='office_address'
                          className='form-label text-uppercase'
                        >
                          Alamat Kantor <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('office_address')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.province}
                        message={errors.province?.message}
                      >
                        <Label
                          htmlFor='province'
                          className='form-label text-uppercase'
                        >
                          Provinsi <span className='mandatory'>*</span>
                        </Label>
                        <Controller
                          control={control}
                          name='province'
                          render={({field: {onChange}}) => (
                            <Select
                              value={selectedProvince}
                              options={dataProvinces}
                              onChange={(newValue) => {
                                if (newValue.label) {
                                  onChange(newValue.label)
                                  setSelectedProvince(newValue)
                                }
                              }}
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.city_id}
                        message={errors.city_id?.message}
                      >
                        <Label
                          htmlFor='city_id'
                          className='form-label text-uppercase'
                        >
                          Kota <span className='mandatory'>*</span>
                        </Label>
                        <Controller
                          control={control}
                          name='city_id'
                          render={({field: {onChange, value}}) => (
                            <Select
                              value={
                                dataCities?.find((op) => op.value === value) ??
                                {}
                              }
                              options={dataCities}
                              onChange={(newValue) => {
                                if (newValue.label) {
                                  onChange(newValue.value)
                                }
                              }}
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/participants' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button color='success' type='submit' disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
